import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"
import styled from "@emotion/styled"

import { css } from "@emotion/core"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SakuraText from "../components/sakura-text"

const PaddedBotDiv = styled("div")`
  padding-bottom: 30px;
`

const PinkSpan = styled("span")`
  color: #ff2ea0;
`

const DescBox = styled("div")`
  background-color: #fdf3f6;
  border-radius: 20px 20px 20px 20px;
  padding: 5%;
`

const EleDesc = () => {
  return (
    <DescBox>
      <p>
        <strong>
          <PinkSpan>應用位置：</PinkSpan>
        </strong>
        櫃內、家居空間、不見光位置
      </p>
      <p>
        <strong>
          <PinkSpan>電觸媒：</PinkSpan>
        </strong>
        是利用大氣中的熱能進行分解作用，過程中令空氣中的污染物被氧化並還原至無毒無害氣體；電觸媒在分解過程中，不會製造任何有害氣體，是種環保型材料。
      </p>
      <p>
        <strong>
          <PinkSpan>除甲醛</PinkSpan>
        </strong>{" "}
        – 不需要紫外線和水作為媒介亦可發揮分解甲醛作用。{" "}
      </p>
      <p>
        <strong>
          <PinkSpan>抗霉</PinkSpan>
        </strong>{" "}
        – 屬疏水性，所以抗水力強，能杜絕霉菌。
      </p>
      <p>
        <strong>
          <PinkSpan>抗污</PinkSpan>
        </strong>{" "}
        – 屬疏油性，所以抗油強，能杜絕油污。
      </p>
    </DescBox>
  )
}
const LightDesc = () => {
  return (
    <DescBox>
      <p>
        <strong>
          <PinkSpan>應用位置：</PinkSpan>
        </strong>
        實木傢俬、傢俬、地板、窗廉、天花、牆身
      </p>
      <p>
        <strong>
          <PinkSpan>光觸媒：</PinkSpan>
        </strong>
        遇到光便會發揮分解作用；櫻花家居獨家代理日本東芝光觸媒，因為它在日本擁有百多項專利，比其他光觸媒更強效30倍，除了有基本除甲醛功效之外，東芝光觸媒更可以達致以下效用。
      </p>
      <p>
        <strong>
          <PinkSpan>除甲醛</PinkSpan>
        </strong>{" "}
        – 把空氣中的有毒有害的有機物，分解成(CO2)二氧化碳和(H2O)水{" "}
      </p>
      <p>
        <strong>
          <PinkSpan>抗菌</PinkSpan>
        </strong>{" "}
        – 抑制多種霉菌生長，例如：黑曲霉
      </p>
      <p>
        <strong>
          <PinkSpan>抗病毒</PinkSpan>
        </strong>{" "}
        – 可以消滅禽流感病毒(H9N2亞型)
      </p>
      <p>
        <strong>
          <PinkSpan>除異味</PinkSpan>
        </strong>{" "}
        – 強力氧化各種臭味，迅速消除室內異味
      </p>
    </DescBox>
  )
}

const AirDesc = () => {
  return (
    <DescBox>
      <p>
        <strong>
          <PinkSpan>應用位置：</PinkSpan>
        </strong>
        室內家居空間
      </p>
      <p>
        <strong>
          <PinkSpan>空氣觸媒：</PinkSpan>
        </strong>
        納米觸媒活性材料，屬於中性水溶液，沒有腐蝕性；在空氣的作用下，產生分解功能，將甲醛催化成二氧化碳和水，即無害無毒氣體。
      </p>
      <p>
        <strong>
          <PinkSpan>除甲醛</PinkSpan>
        </strong>{" "}
        – 空氣觸媒中的納米粒子會滲透在空氣中，從而能夠降解空氣中的遊離甲醛{" "}
      </p>
      <p>
        <strong>
          <PinkSpan>淨化</PinkSpan>
        </strong>{" "}
        – 納米粒子會在短時間迅速分解空間中的甲醛，降低甲醛濃度。
      </p>
      <p>
        <strong>
          <PinkSpan>環保</PinkSpan>
        </strong>{" "}
        – 在分解過程中，最後我生成物是二氧化碳和，不會對環境造成污染。
      </p>
    </DescBox>
  )
}

const GluDesc = () => {
  return (
    <DescBox>
      <p>
        <strong>
          <PinkSpan>應用位置：</PinkSpan>
        </strong>
        木製傢俬、隙縫、觸媒噴不到的位置
      </p>
      <p>
        <strong>
          <PinkSpan>葡萄糖素：</PinkSpan>
        </strong>
        甲醛捕捉劑，原理是從源頭封鎖並吸收甲醛；葡萄糖素採用放射射線照射生技術，在日本已取得專利，吸附捕捉空氣中的甲醛，將轉化為類氨基酸物質和水。
      </p>
      <p>
        <strong>
          <PinkSpan>除甲醛</PinkSpan>
        </strong>{" "}
        – 不需要紫外線和水作為媒介亦可發揮分解甲醛作用。
      </p>
      <p>
        <strong>
          <PinkSpan>安全</PinkSpan>
        </strong>{" "}
        – 通過日本生物科學中心《口服毒性》，《皮膚刺激性》，《眼粘膜刺激恆》
      </p>
      <p>
        <strong>
          <PinkSpan>無二次污染</PinkSpan>
        </strong>{" "}
        – 整個吸附甲醛過程，不會產生二次污染
      </p>
    </DescBox>
  )
}

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query techQuery {
      sofa: file(relativePath: { eq: "service-5-01.png" }) {
        childImageSharp {
          resize(width: 200) {
            src
          }
        }
      }
      air: file(relativePath: { eq: "air-catalyst-zh.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 992) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      glu: file(relativePath: { eq: "glucogan-zh.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 992) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      light: file(relativePath: { eq: "light-catalyst-zh.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 992) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ele: file(relativePath: { eq: "electro-catalyst-zh.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 992) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  const padBotCss = css`
    padding-bottom: 30px;
  `

  return (
    <Layout>
      <SEO
        title="櫻花除甲醛技術 - 櫻花家居服務"
        description="櫻花除甲醛技術 - 櫻花家居服務"
      />
      <div>
        <Container className="home-banner">
          <Row>
            <Col xl={12}>
              <h1>
                <PaddedBotDiv>
                  <SakuraText text="櫻花技術" size={28} />
                </PaddedBotDiv>
              </h1>
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col css={padBotCss} lg={9} sm={12}>
              <div
                css={css`
                  color: #5f4235;
                `}
              >
                <h3>
                  <strong>4種技術 3款套餐</strong>
                </h3>
              </div>
              <div>
                櫻花家居知道香港每個室內環境均需不同層面的空氣淨化服務，所以，櫻花家居特意設計三種個人化的靈活套餐，讓您自由地選擇適合你室內環境的淨化服務；不論您是想改善家居質素，或是想提升辦公室工作環境質素，櫻花目的是使用專業技術令你重新得到一個舒適居住和工作空間。
              </div>
            </Col>
            <Col css={padBotCss} className="d-none d-lg-block">
              <img src={data.sofa.childImageSharp.resize.src} />
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col css={padBotCss} lg={7} md={12}>
              <div>
                <Img
                  style={{ borderRadius: "2% 2% 2% 2%" }}
                  fluid={data.air.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col css={padBotCss}>
              <AirDesc />
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col className="d-none d-lg-block" css={padBotCss}>
              <GluDesc />
            </Col>
            <Col css={padBotCss} lg={7} md={12}>
              <div>
                <Img
                  style={{ borderRadius: "2% 2% 2% 2%" }}
                  fluid={data.glu.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col className="d-lg-none" css={padBotCss}>
              <GluDesc />
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col css={padBotCss} lg={7} md={12}>
              <div>
                <Img
                  style={{ borderRadius: "2% 2% 2% 2%" }}
                  fluid={data.light.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col css={padBotCss}>
              <LightDesc />
            </Col>
          </Row>
          <Row css={padBotCss}>
            <Col className="d-none d-lg-block" css={padBotCss}>
              <EleDesc />
            </Col>
            <Col css={padBotCss} lg={7} md={12}>
              <div>
                <Img
                  style={{ borderRadius: "2% 2% 2% 2%" }}
                  fluid={data.ele.childImageSharp.fluid}
                />
              </div>
            </Col>
            <Col className="d-lg-none" css={padBotCss}>
              <EleDesc />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}
export default ThePage
